import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  interviewData: null
};

const interviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    setInterviewData(state, action) {
      state.interviewData = action.payload;
    },
    resetInterview(state) {
      state.interviewData = null;
    },
  },
});

export const { setInterviewData } = interviewSlice.actions;
export default interviewSlice.reducer;

import { React } from 'react';
import { Modal } from "react-bootstrap"
import OrgContactUsForm from '../../components/OrgContactUsForm';
import { TrialSignUpOrg } from './TrialSignUpOrg';

const TrialSignUpOrgModal = ({ show, onHide, email, invited }) => {

  return (
    <Modal show={show} onHide={onHide} className="no-border">
      <Modal.Header closeButton className='gr-bg text-light'>
        <button className="btn-close invisible" disabled={true}></button>
        <Modal.Title>Sign up for Trial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          invited
          ?
          <TrialSignUpOrg email={email} />
          :
          <OrgContactUsForm email={email} source={"trialsignupform"} />
        }
      </Modal.Body>
    </Modal>
  );
};

export default TrialSignUpOrgModal;
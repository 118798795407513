import React, { useEffect } from "react";
import { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Form } from "react-bootstrap";
import { PossoPhoneInput } from "./PossoPhoneInput";
import validator from "validator";

const OrgContactUsForm = (props) => {

    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const [contactForm, setContactForm] = useState({ source: "homepagev2" })

    useEffect(() => {
        if (props?.source) {
            setContactForm((prevForm) => ({
                ...prevForm,
                source: props.source
            }))
        }
        if (props?.email) {
            setContactForm((prevForm) => ({
                ...prevForm,
                email: props.email
            }))
        }
    }, [props.source, props.email])

    const handleChangeName = (e) => {
        setContactForm({
            ...contactForm,
            name: e.currentTarget.value
        })
    }

    const handleChangeEmail = (e) => {
        setContactForm({
            ...contactForm,
            email: e.currentTarget.value
        })
    }

    const handleChangeCompany = (e) => {
        setContactForm({
            ...contactForm,
            company: e.currentTarget.value
        })
    }

    const handleChangePhone = (e) => {
        setContactForm({
            ...contactForm,
            phone: e
        })
    }

    const handleContactFormSubmit = (e) => {
        if (!contactForm.name) {
            setErrorMsg("Please enter a valid name.")
            return
        }
        if (!contactForm.company) {
            setErrorMsg("Please enter a valid company name.")
            return
        }
        if (!contactForm.email || !validator.isEmail(contactForm.email)) {
            setErrorMsg("Please enter a valid email.")
            return
        }
        if (!contactForm.phone || !isValidPhoneNumber(contactForm.phone)) {
            setErrorMsg("Please enter a valid phone number.")
            return
        }
        const requestOptions = {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(contactForm)
        };
        fetch(`${process.env.REACT_APP_API_URL}/contact_us_form`, requestOptions)
            .then(response => response.json())
            .then(
                (result) => {
                    if (props.onDismiss) {
                        props.onDismiss()
                    }
                    setErrorMsg('')
                    if (contactForm.source === 'homepagev2') {
                        setSuccessMsg('Thank you for your interest in PossoBuild SmartHire! Our team will reach out soon to schedule a demo for you.')
                    } else {
                        setSuccessMsg('Thank you for your interest in PossoBuild SmartHire! Our team will reach out soon to enroll you in our trial program.')
                    }
                }
            )

    }

    return <>
        <div className="ms-auto me-auto" style={{ maxWidth: 400 + "px" }}>
            <form className="contact-us">
                <div class="mb-3">
                    <input type="text" class="form-control request-demo-page-form-control" id="" placeholder="Name" onChange={handleChangeName} />
                </div>
                <div class="mb-3">
                    <input type="emtextail" class="form-control request-demo-page-form-control" id="" placeholder="Company" onChange={handleChangeCompany} />
                </div>
                <div class="mb-3">
                    <input type="email" class="form-control request-demo-page-form-control" id="" placeholder="Email address" onChange={handleChangeEmail} />
                </div>
                <div class="mb-3">
                    <PossoPhoneInput placeholder="Phone number" onChange={handleChangePhone} />
                </div>
                <Form.FloatingLabel className="text-danger">{errorMsg}</Form.FloatingLabel>
                <Form.FloatingLabel className="text-success">{successMsg}</Form.FloatingLabel>
                <div className="text-end mb-5 mb-lg-0"> <a id="contactusbtn" onClick={handleContactFormSubmit} class="btn btn-primary">Submit</a> </div>
            </form>
        </div>
    </>
}

export default OrgContactUsForm